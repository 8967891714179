/********************************************************************************
 * jQuery.nextOrFirst()
 *
 * PURPOSE: Works like next(), except gets the first item from siblings if there is no "next" sibling to get.
 ********************************************************************************/
jQuery.fn.nextOrFirst = function (selector) {
	var next = this.next(selector);
	return next.length ? next : this.prevAll(selector).last();
};

/********************************************************************************
 * jQuery.prevOrLast()
 *
 * PURPOSE: Works like prev(), except gets the last item from siblings if there is no "prev" sibling to get.
 ********************************************************************************/
jQuery.fn.prevOrLast = function (selector) {
	var prev = this.prev(selector);
	return prev.length ? prev : this.nextAll(selector).last();
};

RegExp.escape = function (str) {
	return (str + '').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, '\\$1');
};

var ww = window.innerWidth;
var wh = window.innerHeight;
var mobileWindowWidth = 768;
var tabletWindowWidth = 1039;
var carouselMoveStep = 0;
var carouselFrontWidth = 0;

// Timeout ID which is set when resizing the window
var timeoutID;
// Is the current view for mobile devices or not
var mobileVersion = $(window).width() <= mobileWindowWidth ? true : false;

$(document).ready(function () {
	setCookieInfo();

	//Megamenu search icon click
	$('#header .search-submit').click(function () {
		var $searchInput = $(this).siblings('input[type=text]');
		if ($searchInput.val() === '') {
			$searchInput.focus();
			return false;
		}
	});

	//e-mail protection init
	$('body').emailSpamProtection('email');

	//tab navigation
	$('.tabs.tab-navigation ul li a').click(function (event) {
		if ($(this).parents('.tabs.tab-navigation').attr('id') == 'contactFinderTabs') return true;
		if ($(this).parents('.tabs.tab-navigation').attr('id') == 'catTabs') return true;
		$(this).blur();
		var selectedItem = $(this).parent().index();
		if (ww > mobileWindowWidth) {
			// tab content in different place when used 2 column layout
			var tabCont;
			if ($(this).closest('.content-2').length > 0)
				tabCont = $(this).closest('.tabs').parent().find('.tab-content > div');
			else tabCont = $('.tab-content > div');

			var tabContToShow = tabCont.eq(selectedItem);
			$(this).closest('.tabs').find('ul li').toggleClass('selected', false);
			$(this).parent().toggleClass('selected', true);
			tabCont.each(function () {
				if (!$(this).hasClass('bottom-links')) {
					$(this).toggleClass('hide', true);
				}
			});

			tabContToShow.toggleClass('hide', false);

			var map = tabContToShow.find('.map-canvas');
			if (map.length > 0)
				if (typeof baiduMapsActive != 'undefined') {
					initializeBaiduMapById(map.attr('id'));
				} else {
					initializeMapById(map.attr('id'));
				}
		}
		//for mobiles
		else {
			var mobileCont = $(this).next('.dropdown');
			//close tab
			if ($(this).parent().hasClass('selected')) {
				$(this).parent().toggleClass('selected', false);
			}
			//open tab
			else {
				$(this).closest('.tabs').find('ul li').toggleClass('selected', false);
				var tabHeight = $(this).parent().outerHeight();
				var headerHeight = $('.header').outerHeight() || 0;
				if (mobileCont.hasClass('mobile-layout-set')) {
					$(this).parent().toggleClass('selected', true);
				}
				//first click on a tab -> copy HTML content from desktop layout
				else {
					//moving the desktop selected tab content DOM object into mobile selected tab content DOM object - except social media
					if (!$(this).hasClass('social-media')) {
						// tab content in different place when used 2 column layout
						var tabCont;
						if ($(this).closest('.content-2').length > 0)
							tabCont = $(this).closest('.tabs').parent().find('.tab-content > div');
						else tabCont = $('.tab-content > div');

						//moving the desktop selected tab content DOM object into mobile selected tab content DOM object - except social media
						var tabContToShow = tabCont.eq(selectedItem);
						tabCont.eq(selectedItem).after("<div data-for='test' class='hide' />");
						mobileCont.html('');
						mobileCont.append(tabContToShow.toggleClass('hide', false));
						mobileCont.addClass('mobile-layout-set');
					}
					$(this).parent().toggleClass('selected', true);
				}
				//scrolling to the element
				window.scrollTo(0, mobileCont.offset().top - headerHeight - tabHeight - tabHeight / 3);
			}
		}

		return false;
	});

	//------------------------mobile layouts
	if (ww <= mobileWindowWidth) {
		//initialising onload tab navigation content for mobiles
		$('.tabs.tab-navigation ul li.selected').each(function () {
			//moving the desktop selected tab content DOM object into mobile selected tab content DOM object - except social media
			if (!$(this).hasClass('social-media')) {
				var tabCont;
				$(this).find('.dropdown').html('');
				if ($(this).parents('.tabs.tab-navigation').attr('id') == 'contactFinderTabs') {
					tabCont = $('.tab-content > div');

					$(tabCont).after("<div class='hide'></div>");
					$(this).find('.dropdown').append($(tabCont));
				} else {
					if ($(this).closest('.content-2').length > 0)
						tabCont = $(this).closest('.tabs').parent().find('.tab-content > div');
					else tabCont = $('.tab-content > div');

					$(tabCont).eq($(this).index()).after("<div class='hide'></div>");
					$(this)
						.find('.dropdown')
						.append($(tabCont).eq($(this).index()));
				}
				$(this).find('.dropdown').addClass('mobile-layout-set');
			}
		});

		//moving blocks of content in DOM structure for mobile layouts
		$('.mobile-cont').each(function () {
			if (!$(this).hasClass('mobile-layout-set')) {
				var identify = $(this).attr('id');
				var contToMove = $('[data-for=' + identify + ']');
				$(this).html(contToMove.toggleClass('hide', false));
				$(this).addClass('mobile-layout-set');
				$(this)
					.find($('[data-for=' + identify + ']'))
					.removeAttr('data-for');
			}
		});

		//all-cases - initialising select boxes for mobile
		$('#cases-filter .column').each(function (index) {
			var selectBox = $(this).find('select');
			if (!selectBox.hasClass('mobile-layout-set')) {
				var filters = [];
				var htmlSelectBox = '';
				$(this)
					.find('label')
					.each(function () {
						var value = $(this).prev().attr('value');
						var selected = $(this).prev().prop('checked');
						filters.push({
							label: $(this).text(),
							value: value,
							selected: selected,
						});
					});
				for (i = 0; i < filters.length; ++i) {
					htmlSelectBox += "<option value='" + filters[i].value + "'";

					if (filters[i].selected) htmlSelectBox += " selected='selected' ";

					htmlSelectBox += '>' + filters[i].label + '</option>';
				}
				selectBox.html(htmlSelectBox);
				selectBox.addClass('mobile-layout-set');
			}
		});

		//contact finder tab navigation for mobile
		if ($('.tabs.tab-navigation.contact-finder-tabs').length > 0) {
			$('.tabs.tab-navigation.contact-finder-tabs').toggleClass('contact-finder-tabs', false);
		}
	}

	//responsive tables - product spec.
	$('div.responsive table').each(function () {
		//setting inline heights for 1st column in tablet layout
		if (ww > mobileWindowWidth && ww <= tabletWindowWidth) {
			$(this)
				.find('tbody')
				.find('tr')
				.each(function () {
					var tdTags = $(this).find('td');
					var tdTagFirst = $(this).find('td:first-child div');
					var maxHeight = 0;
					for (i = 0; i < tdTags.length; ++i) {
						if ($(tdTags[i]).height() > maxHeight) {
							maxHeight = $(tdTags[i]).height();
						}
					}
					tdTagFirst.height(maxHeight);
				});
			$(this)
				.find('thead')
				.find('tr')
				.each(function () {
					var thTags = $(this).find('th');
					var thTagFirst = $(this).find('th:first-child span');
					var maxHeight = 0;
					for (i = 0; i < thTags.length; ++i) {
						if ($(thTags[i]).height() > maxHeight) {
							maxHeight = $(thTags[i]).height();
						}
					}
					thTagFirst.height(maxHeight);
				});
		}
		var thTags = $(this).find('th');
		var headers = Array(thTags.length);
		for (i = 0; i < headers.length; ++i) {
			headers[i] = $(thTags[i]).text();
		}
		$(this)
			.find('tbody')
			.find('tr')
			.each(function () {
				var tdTags = $(this).find('td');
				for (i = 0; i < tdTags.length; ++i) {
					$(tdTags[i]).attr('data-title', headers[i]);
				}
			});
	});

	//responsive tables - HTML editor
	$('table.responsive-table').each(function () {
		var $table = $(this),
			$thead = $table.find('thead'),
			$tbody = $table.find('tbody');

		// get column count from 1st row in the body
		var columnCount = $tbody.find('tr:first-child td').length;

		// extract column titles
		var titles = new Array();

		$thead.find('tr').each(function () {
			var $cells = $(this).find('td');
			var nbsp = $('<td/>').html('&nbsp;').text();

			if ($cells.length == columnCount) {
				$cells.each(function (ix, el) {
					titles[ix] = $(el).html() == '&nbsp;' ? nbsp : $(el).html();
				});
			}
		});

		$tbody.find('tr').each(function () {
			$(this)
				.find('td')
				.each(function (ix, el) {
					$(el).attr('data-title', titles[ix]);
				});
		});
	});

	// files-list
	if ($('#files-list.results').length > 0) {
		// hide navigation links, useful only when javascript disabled or for Google bots
		$('#allFilesNaviLinks').hide();

		// initialize infinite scrolling
		if ($('#files-list').find('a.nextLink').length > 0) {
			$('#files-list').find('div.scroll').jscroll({
				nextSelector: 'a.nextLink:last',
			});
		}
	}

	// equipment section main page sorting
	$('.equipment-tab a').click(function () {
		if ($(this).parent().hasClass('selected')) return false;
		// change selected tab
		$(this).closest('ul').find('li').toggleClass('selected');

		// find all containers and items to sort
		var containers = $('div.tab-sorting').find('div.content-3.equipment');
		var items = $('div.tab-sorting').find('div.sort-item');

		sortEquipmentCategories($(this).attr('class'), containers, items);
		return false;
	});

	$('.images-tab a').click(function () {
		if (!$(this).closest('div').hasClass('images-tab')) {
			return false;
		}
		if ($(this).parent().hasClass('selected')) return false;
		// change selected tab
		$(this).closest('ul').find('.selected').attr('class', '');
		$(this).closest('li').attr('class', 'selected');
		// selected tab name
		var imageTabName = $('div.tab-sorting').find('.selected a')[0].innerHTML;
		// hide previous tab
		var containers = $('div.tab-sorting').find('.visible');
		containers.attr('class', 'image-tab hide');
		// show new tab
		containers = $('div.tab-sorting').find('#' + imageTabName);
		containers.attr('class', 'image-tab visible');

		return false;
	});

	/*
        Global search autocomplete
    */
	var lang = $('html').attr('lang');
	var allowedChars = new RegExp(/^[a-zA-Z\s]+$/);

	function charsAllowed(value) {
		return allowedChars.test(value);
	}
	var globalSearchForms = document.querySelectorAll('.globalSearch');
	globalSearchForms.forEach(function (theForm) {
		autocomplete({
			input: theForm.querySelector('.search-query'),
			minLength: 2,
			onSelect: function (item, inputfield) {
				inputfield.value = item.label;
				theForm.submit();
			},
			fetch: function (text, callback) {
				$.ajax({
					url: '/' + lang + '/api/autocomplete/search?q=' + $.trim(text),
					dataType: 'json',
					success: function (data) {
						callback(
							$.map(data.Data, function (item) {
								return {
									label: item,
								};
							})
						);
					},
				});
			},
			render: function (item, value) {
				var itemElement = document.createElement('div');
				if (charsAllowed(value)) {
					var regex = new RegExp(value, 'gi');
					var inner = item.label.replace(regex, function (match) {
						return '<strong>' + match + '</strong>';
					});
					itemElement.innerHTML = inner;
				} else {
					itemElement.textContent = item.label;
				}
				return itemElement;
			},
			emptyMsg: '',
			customize: function (input, inputRect, container, maxHeight) {
				container.classList.add('global-autocomplete-menu');
			},
		});
	});

	$('.mapLink').click(function () {
		var id = $(this).parents('.content-3').siblings('.map').children('.map-canvas').attr('id');
		maps[id].showToolTipByIndex($(this).attr('data-index'));
		$.scrollTo('#map-canvas-offices', { duration: 500, offset: -50 });
		return false;
	});

	$('#dealersLink').click(function () {
		$('html, body').animate(
			{
				scrollTop: $('#mapSearch').offset().top,
			},
			1000
		);
		return false;
	});

	$('#salesContactsFilter').change(function () {
		filterContacts();
		filterContactsPreselect = false;
	});
	if ($('#salesContactsFilter').length > 0 && $('#salesContactsFilter').val().length > 0) {
		filterContacts();
	}

	$('#dealersFilter').change(function () {
		searchDealer();
		filterContactsPreselect = false;
	});

	$('#mapSearchForm input[type=submit]').on('click', function (e) {
		e.preventDefault();
		searchDealer();
	});

	$('.footer__links__button').each(function (el) {
		$(this).on('focus', function () {
			handlePopupPosition(this);
		});
		$(this).on('mouseenter', function () {
			handlePopupPosition(this);
		});

		$(this).on('blur', function () {
			$(this).find('.popup-content').css('display', 'none');
		});
		$(this).on('mouseleave', function () {
			$(this).find('.popup-content').css('display', 'none');
		});
	});
});

/**
 * Function to handle the position of the popup
 * @param {HTMLElement | null} button
 */
function handlePopupPosition(button) {
	if (!button) return;

	const popup = $(button).find('.popup-content');
	popup.css('display', 'flex');

	adjustPopupPosition(popup[0]);
}

/**
 * Function to handle the position of the popup
 * @param {HTMLElement} popup
 */
function adjustPopupPosition(popup) {
	const rect = popup.getBoundingClientRect();

	if (rect.bottom > window.innerHeight) {
		popup.style.bottom = 'auto';
		popup.style.top = '100%';
	}

	if (rect.top < 0) {
		popup.style.top = 'auto';
		popup.style.bottom = '100%';
	}

	if (rect.right > window.innerWidth) {
		popup.style.right = '0';
		popup.style.left = 'auto';
	}

	if (rect.left < 0) {
		popup.style.left = '0';
		popup.style.right = 'auto';
	}
}

var filterContactsPreselect = true;
function filterContacts(selectedContacts) {
	var showAll = true;

	var categoryElement = allowContentFiltering ? '#dealersFilter' : '#salesContactsFilter';
	var valueField = $(categoryElement);
	var value;
	if (valueField.length > 0) {
		value = valueField.val();
	}

	if (value != undefined) {
		$('#stdContent').addClass('hide');
		$('#filteredContent').removeClass('hide');

		$('#filteredContent').html('');
		var index = 0;
		var container;
		var findContacts = $('#stdContent').find('a[data-id=' + value + ']');
		if (findContacts.length > 0) {
			for (var i = 0; i < findContacts.length; ++i) {
				if (index % 3 == 0) {
					container = $('<div class="content-3 contact-list"></div>');
					$('#filteredContent').append(container);
				}
				var item = $(findContacts[i]).parent().parent().parent().clone();
				item.attr('class', 'column-' + ((index % 3) + 1));
				container.append(item);
				index++;
			}
		}
		showAll = value.length == 0;
	}

	if (selectedContacts) {
		if (selectedContacts.length > 0) {
			$('#stdContent').addClass('hide');
			$('#filteredContent').removeClass('hide');

			$('#filteredContent').html('');
			var index = 0;
			var container;
			for (var i = 0; i < selectedContacts.length; ++i) {
				var contentID = selectedContacts[i].data_con_content_id;
				var findContact = $('#stdContent').find('[data-content-id=' + contentID + ']');
				if (findContact.length > 0) {
					if (index % 3 == 0) {
						container = $('<div class="content-3 contact-list"></div>');
						$('#filteredContent').append(container);
					}
					var item = $(findContact).parent().clone();
					item.attr('class', 'column-' + ((index % 3) + 1));
					container.append(item);
					index++;
				}
			}
		}
		showAll = false;
	}

	if (showAll) {
		$('#stdContent').removeClass('hide');
		$('#filteredContent').addClass('hide');
	}
}

function toggleValidationError(el, show) {
	if (show) {
		el.addClass('input-validation-error');
		el.siblings('.error-message').show();
	} else {
		el.removeClass('input-validation-error');
		el.siblings('.error-message').hide();
	}
}

function IsValidEmail(email) {
	if (email.length === 0) return true;

	var emailPattern =
		/^[a-zA-Z0-9]+[\w-]*([\.-]+[a-zA-Z0-9]+)*@[a-zA-Z0-9][a-zA-Z0-9-]+\.([a-zA-Z0-9-]+\.){0,2}[a-zA-Z]{2,10}$/;
	return emailPattern.test(email);
}

function IsValidUrl(url) {
	if (url.length === 0) return true;

	if (url.indexOf('http://') !== 0 && url.indexOf('https://')) url = 'http://' + url;

	return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
		url
	);
}

function validateInputsWithXor(form) {
	// validates set of inputs marked with .xor class. Valid if only one of those inputs is set
	var xorInputs = $(form).find('.xor');
	var valSet = 0;
	var valid = true;

	xorInputs.each(function () {
		var input = $(this);
		if (input.val().length > 0) {
			valSet++;
		}
	});

	if (valSet != 1) {
		$('.xor-error').addClass('active');
		xorInputs.each(function () {
			$(this).addClass('input-validation-error');
		});
		valid = false;
	} else {
		$('.xor-error').removeClass('active');
		xorInputs.each(function () {
			if (!$(this).siblings('.error-message').is(':visible')) {
				$(this).removeClass('input-validation-error');
			}
		});
	}

	return valid;
}

function sortEquipmentCategories(sortType, containers, items) {
	var sortAttr = '';
	if (sortType == 'sort-popular') sortAttr = 'data-index';
	else if (sortType == 'sort-alphabetical') sortAttr = 'data-alph';

	// divide sortable items to proper containers and set correct column class
	for (i = 0; i < items.length; i++) {
		var item = items.filter('div[' + sortAttr + '="' + i + '"]');
		item.attr('class', 'column-' + ((i % 3) + 1) + ' sort-item');
		$(containers[Math.floor(i / 3)]).append(item);
	}
}

function rotate(offset, obj, move, controls) {
	var slider = obj.find('ul');
	var movement = move * offset;
	var leftPosition = -movement;
	// slide left
	if (offset < 0) {
		//move last item(s) to the start
		for (var i = 0; i < -offset; i++) slider.find('li:first').before(slider.find('li:last'));

		//set the slider to correct position
		slider.css({ left: movement });
		leftPosition = 0;
	}

	//console.log(controls.html());
	slider.animate({ left: leftPosition }, 500, function () {
		// slide right
		if (offset > 0) {
			//move first item(s) to the end
			for (var i = 0; i < offset; i++) slider.find('li:last').after(slider.find('li:first'));

			//set the slider to correct position
			slider.css({ left: 0 });
		}

		//set the dot controls
		if (obj.hasClass('dots')) {
			var dots = controls.children('a');
			var inactive = dots.not('.inactive').toggleClass('inactive', true);
			$(dots[(inactive.index() + offset) % dots.length]).toggleClass('inactive', false);
		}
	});
}

//whether it is an int or float
function isInt(n) {
	return n % 1 === 0;
}

function resetMap() {
	$('#mapSearch input[name=address-map]').val('');
	var firstItem = $($('#dealersFilter option')[0]);
	//$("#dealersFilter").selectbox('change', firstItem.val(), firstItem.html());

	if (dealerMarker != null) {
		maps['map-canvas-sales'].removeMarker(dealerMarker);
	}

	maps['map-canvas-sales'].showMarkers();
	if (allowContentFiltering && $('#dealersFilter').length == 0) {
		filterContacts();
	}
	return false;
}

$('#resetMapButton').on('click', function () {
	resetMap();
});

function reverseGeocodingInvoke() {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(reverseGeocoding);
	} else {
		alert('Geolocation is not supported.');
	}
	return false;
}

$('#reverseGeocodingInvokeButton').on('click', function () {
	reverseGeocodingInvoke();
});

function searchDealer() {
	var address = $('#mapSearch input[name=address-map]').val();
	var selectedCategory = $('#dealersFilter').val();

	var toShow = new Array();

	// category search
	var map = maps['map-canvas-sales'];
	var markers = map.mapMarkersObjects;
	if (selectedCategory) {
		if (selectedCategory.length > 0) {
			for (var i = 0; i < markers.length; ++i) {
				//if (markers[i].data_cat_content_id.indexOf(selectedCategory) != -1) toShow.push(markers[i].data_index);
				if (markers[i].data_cat_content_id.indexOf(selectedCategory) != -1) toShow.push(markers[i]);
			}
		}
	}

	// address search
	// no address given
	if (address.length == 0) {
		// clear green marker
		if (dealerMarker != null) {
			map.removeMarker(dealerMarker);
		}

		map.showMarkers(toShow);
		if (allowContentFiltering) {
			filterContacts();
		}
		return false;
	}

	// address is given - geocode or perform search if alredy geocoded
	if (geoCodedAddress != address) {
		geocoding(address, toShow);
	} else {
		performDealerSearch(toShow);
	}

	return false;
}

function geocoding(address, validIndexes) {
	if (typeof baiduMapsActive == 'undefined') {
		var geocoder = new google.maps.Geocoder();

		if (geocoder) {
			geocoder.geocode({ address: address }, function (results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					var loc = results[0].geometry.location;
					dealerLng = loc.lng();
					dealerLat = loc.lat();
					geoCodedAddress = address;
					return performDealerSearch(validIndexes);
				}
			});
		}
	} else {
		var geocoder = new BMap.Geocoder();

		if (geocoder) {
			geocoder.getPoint(address, function (point) {
				if (point) {
					dealerLng = point.lng;
					dealerLat = point.lat;
					geoCodedAddress = address;

					return performDealerSearch(validIndexes);
				}
			});
		}
	}
}

function reverseGeocoding(position) {
	if (typeof baiduMapsActive == 'undefined') {
		var geocoder = new google.maps.Geocoder();

		if (geocoder) {
			var latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
			geocoder.geocode({ location: latlng }, function (results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					$('input#addressInput').val(results[0].formatted_address);
					var loc = results[0].geometry.location;
					dealerLng = loc.lng();
					dealerLat = loc.lat();
					geoCodedAddress = results[0].formatted_address;
					searchDealer();
				}
			});
		}
	} else {
		var geocoder = new BMap.Geocoder();

		if (geocoder) {
			var point = new BMap.Point(position.coords.longitude, position.coords.latitude);
			geocoder.getLocation(point, function (result) {
				if (result.point) {
					$('input#addressInput').val(result.address);
					dealerLng = point.lng;
					dealerLat = point.lat;
					geoCodedAddress = result.address;
					searchDealer();
				}
			});
		}
	}
}

function performDealerSearch(selectedMarkers) {
	var validIndexes = new Array();
	for (var i = 0; i < selectedMarkers.length; ++i) {
		validIndexes.push(selectedMarkers[i].data_index);
	}

	var map = maps['map-canvas-sales'].map;
	var position;
	if (typeof baiduMapsActive == 'undefined') {
		position = new google.maps.LatLng(dealerLat, dealerLng);
		// green marker
		if (dealerMarker != null) dealerMarker.setMap(null);
		dealerMarker = new google.maps.Marker({
			position: position,
			map: map,
			icon: 'https://www.google.com/intl/en_us/mapfiles/ms/micons/green-dot.png',
		});
	} else {
		position = new BMap.Point(dealerLng, dealerLat);
		// green marker
		if (dealerMarker != null) map.removeOverlay(dealerMarker);
		dealerMarker = new BMap.Marker(position, {
			map: map,
			icon: new BMap.Icon('/Static/img/green-dot.png', new BMap.Size(32, 32)),
		});
		map.addOverlay(dealerMarker);
	}

	// calculate distances and sort
	var markers = maps['map-canvas-sales'].mapMarkersObjects;
	var distances = new Array();
	for (var i = 0; i < markers.length; ++i) {
		if (validIndexes.length == 0 || validIndexes.indexOf(markers[i].data_index) != -1) {
			if (typeof baiduMapsActive == 'undefined') {
				var distance = google.maps.geometry.spherical.computeDistanceBetween(markers[i].position, position);
				if (isNaN(distance)) {
					console.log(
						'Wrong geo position values! Content ID: ',
						markers[i].data_con_content_id,
						markers[i].title
					);
				} else {
					//markers[i].distance = distance;
				}
				distances.push(new Array(distance, markers[i]));
			} else {
				var distance = map.getDistance(markers[i].point, position);
				//markers[i].distance = distance;
				distances.push(new Array(distance, markers[i]));
			}
		}
	}
	distances.sort(function (a, b) {
		return a[0] - b[0];
	});

	var toShow = new Array();
	for (var i = 0; i < 3 && i < distances.length; ++i) toShow.push(distances[i][1]);

	map.distanceResult = toShow;

	if (typeof baiduMapsActive == 'undefined') {
		var service = new google.maps.DistanceMatrixService();

		var requestOrigins = new Array();
		for (var i = 0; i < toShow.length; ++i) {
			requestOrigins.push(toShow[i].position);
		}

		service.getDistanceMatrix(
			{
				origins: requestOrigins,
				destinations: [position],
				travelMode: 'DRIVING',
				unitSystem: _metricUnits ? google.maps.UnitSystem.METRIC : google.maps.UnitSystem.IMPERIAL,
				avoidHighways: false,
				avoidTolls: false,
			},
			callbackDistanceMetrix
		);
	}

	maps['map-canvas-sales'].showMarkers(toShow, position);

	if (allowContentFiltering) {
		filterContacts(toShow);
	}

	return false;
}

function callbackDistanceMetrix(response, status) {
	if (status == 'OK') {
		var map = maps['map-canvas-sales'].map;
		var origins = response.originAddresses;
		//var destinations = response.destinationAddresses;

		for (var i = 0; i < origins.length; i++) {
			var results = response.rows[i].elements;
			for (var j = 0; j < results.length; j++) {
				var element = results[j];

				map.distanceResult[i].travelTime = element.duration.text;
				map.distanceResult[i].distanceStr = element.distance.text;
				map.distanceResult[i].distance = element.distance.value;
			}
		}
	}
}

KLMR.setCookie = function (name, value, days) {
	let expires = '';
	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toGMTString();
	}
	document.cookie = name + '=' + value + expires + '; path=/';
};

KLMR.getCookie = function (name) {
	let nameEQ = name + '=';
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(nameEQ) == 0) {
			return c.substring(nameEQ.length, c.length);
		}
	}
	return null;
};

function deleteCookie(name) {
	KLMR.setCookie(name, '', -1);
}

function setCookieInfo() {
	$('#cookieInfo button').click(function () {
		KLMR.setCookie('CookieAccepted', 1, 5000);
		$('#cookieInfo').remove();
	});
}

(function (kr, $, undefined) {
	KLMR.swapChars = function (text, key) {
		// applies XOR function on a text in order to obfuscate/clarify it
		var result = '';
		key = key || '5125';
		for (let i = 0; i < text.length; ++i) {
			result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
		}

		return result;
	};
})((window.kr = window.kr || {}), window.jQuery);

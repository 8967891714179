$(document).ready(function () {
	var $body = $('body'),
		vw = window.innerWidth,
		$header = $body.find('header');

	// Contact Admin tool haven't header element
	if ($header.length == 0) {
		return;
	}

	initDropdowns();
	// Mobile view
	if (vw <= 768) {
		initHamburger();
		initSearchInput();
	}

	function initDropdowns() {
		var $dropdowns = $('[data-js=dropdown]:visible');
		$dropdowns.each(function () {
			var $dropdown = $(this);
			if ($dropdown.data('action') === 'click') {
				$dropdown.on('click', function (e) {
					$dropdown.find('[data-js=dropdown-menu]').hasClass('show') &&
					!$(e.target).parents('[data-js=dropdown]').length
						? hideDropdownMenu()
						: showDropdownMenu();
				});
			} else if ($dropdown.data('action') === 'hover') {
				if (vw > 768) {
					$dropdown.on('mouseover', showDropdownMenu);
					$dropdown.on('mouseout', hideDropdownMenu);
				} else {
					$dropdown.find('.mobile-dropdown-icon').on('click', function (e) {
						$dropdown.hasClass('open') &&
						(!$(e.target).parents('[data-js=dropdown]').length ||
							!$(e.target).hasClass('.mobile-dropdown-icon'))
							? hideDropdownMenu()
							: showDropdownMenu();
					});
				}
			}

			$dropdown.find('[data-js=dropdown-icon]').on('keypress', handleEnterPressOnDropdownIcon);

			function showDropdownMenu() {
				$dropdown.addClass('open');
				$dropdown.find('[data-js=dropdown-icon]').attr('aria-expanded', true);
				$(document).on('keydown', hideDropdownOnEscape);
				$(document).on('click', hideDropdownOnClickOutside);
			}

			function hideDropdownMenu() {
				$dropdown.removeClass('open');
				$dropdown.find('[data-js=dropdown-icon]').attr('aria-expanded', false);
				$(document).off('keydown', hideDropdownOnEscape);
				$(document).off('click', hideDropdownOnClickOutside);
			}

			function hideDropdownOnEscape(e) {
				if (e.which === 27) {
					hideDropdownMenu();
				} else return;
			}

			function hideDropdownOnClickOutside(e) {
				if (!$(e.target).parents('[data-js=dropdown]').length) {
					hideDropdownMenu();
				}
			}

			function handleEnterPressOnDropdownIcon(e) {
				if (e.which === 13) {
					$dropdown.find('[data-js=dropdown-menu]').hasClass('show')
						? hideDropdownMenu()
						: showDropdownMenu();
				}
			}
		});
	}

	function initHamburger() {
		var $hamburger = $body.find('.header__mobile-hamburger:visible');
		$hamburger.find('button').on('click', function () {
			if ($body.find('header').hasClass('dropdown-expanded')) {
				hideDropdownMenu();
			} else {
				showDropdownMenu();
			}
		});

		$hamburger.find('button').on('keypress', handleEnterPressOnDropdownIcon);

		function showDropdownMenu() {
			$hamburger.find('button').attr('aria-expanded', true);
			$body.find('header').addClass('dropdown-expanded');
			$(document).on('keydown', hideDropdownOnEscape);
			$(document).on('click', hideDropdownOnClickOutside);
		}

		function hideDropdownMenu() {
			$hamburger.find('button').attr('aria-expanded', false);
			$body.find('header').removeClass('dropdown-expanded');
			$(document).off('keydown', hideDropdownOnEscape);
			$(document).off('click', hideDropdownOnClickOutside);
		}

		function hideDropdownOnEscape(e) {
			if (e.which === 27) {
				hideDropdownMenu();
			} else return;
		}

		function hideDropdownOnClickOutside(e) {
			if (
				!$(e.target).parents('.header__navigation').length &&
				!$(e.target).parents('.header__mobile-hamburger').length
			) {
				hideDropdownMenu();
			}
		}

		function handleEnterPressOnDropdownIcon(e) {
			if (e.which === 13) {
				$body.find('header').hasClass('dropdown-expanded') ? hideDropdownMenu() : showDropdownMenu();
			}
		}
	}

	function initSearchInput() {
		var $search = $('.header__search');
		$search.find('button').click(function () {
			if (!$search.hasClass('search-open')) {
				$search.addClass('search-open');
				$search.find('input').focus();
				$(document).on('click', hideInputOnClickOutside);
				$(document).on('keydown', hideInputOnEscape);
				return false;
			} else {
				$search.removeClass('search-open');
				$(document).off('click', hideInputOnClickOutside);
				$(document).on('keydown', hideInputOnEscape);
			}
		});

		function hideInputOnClickOutside(e) {
			if (
				!$(e.target).parents('.header_search').length &&
				!$(e.target).parents('.header__mobile-hamburger').length
			) {
				$search.removeClass('search-open');
			}
		}

		function hideInputOnEscape(e) {
			if (e.which === 27) {
				$search.removeClass('search-open');
			} else return;
		}
	}

	$('div.video').on('triggerStopVideo', function () {
		var iframeElement = $(this).find('iframe');
		if (iframeElement.length > 0) {
			// send pause command only to visible items
			if ($(iframeElement).parents('[class*="hide"]').length == 0) {
				// don't use a stopVideo. pauseVideo func - video header remains in player
				iframeElement[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
			}
		}
		return false;
	});
});
